import { createInstance, i18n } from 'i18next';
import type { Resource, ReadCallback, ResourceKey } from 'i18next'
import { initReactI18next } from 'react-i18next/initReactI18next';
import ChainedBackend from 'i18next-chained-backend'
import resourcesToBackend from 'i18next-resources-to-backend';
import { type HttpBackendOptions } from 'i18next-http-backend';
import BrowserLanguageDetector, { type DetectorOptions } from 'i18next-browser-languagedetector';
import i18nConfig from '../config';

export type ImportFn = 
  | ((language: string, namespace: string, callback: ReadCallback) => void) 
  | ((language: string, namespace: string) => Promise<ResourceKey | boolean | null | undefined>)

export interface Params {
  /** @property 현재 다국어 코드 */
  locale: string;
  /** @property 제공되는 네임스페이스 목록 */
  namespaces: string[];
  config?: typeof i18nConfig;
  loadResource?: Resource | ImportFn;
  backendOptions?: HttpBackendOptions;
}

export async function initTranslations({
  locale, 
  namespaces,
  config = i18nConfig,
  loadResource,
  backendOptions
}: Params): Promise<i18n> {
  const i18nInstance = createInstance();
  const isServer = typeof window === 'undefined';
  let detectionOptions:DetectorOptions = {};

  i18nInstance
    .use(initReactI18next)
    .use(ChainedBackend)

  if (!isServer) {
    i18nInstance.use(BrowserLanguageDetector);
    detectionOptions = {
      order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain']
    }
  }

  await i18nInstance.init({
    lng: locale,
    fallbackLng: config.defaultLocale,
    supportedLngs: config.locales,
    defaultNS: namespaces[0],
    fallbackNS: namespaces[0],
    ns: namespaces,
    preload: isServer ? config.locales : [],
    detection: detectionOptions,
    backend: {
      backends: [
        // HttpBackend,
        loadResource ? resourcesToBackend(loadResource) : null
      ].filter(Boolean),
      backendOptions
    }
  });

  return i18nInstance;
}

export default initTranslations;
